<template>
  <div class="content-wrapper">
    <!-- Main content -->
    <section class="content">
      <section style="margin-bottom: 1%">
        <a-button type="primary" @click="createHandle">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('新增预付单') }}
        </a-button>
      </section>
      <a-row :gutter="16">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.orderSn"
            :placeholder="$t('请输入预付单号')"
          />
        </a-col>
        <!-- <a-col :span="6">
          <DateRangePicker
            id="effectiveTime"
            :needhms="false"
            @get_date="chooseBizDate"
            :placeholder="$t(`业务日期`)"
            :start-time="formData.bizDateStart"
            :end-time="formData.bizDateEnd"
          />
        </a-col> -->
        <a-col :span="6">
          <CommonQuerySelect
            api="queryPurchaseSupplierList"
            :params="{
              status: 1,
            }"
            :placeholder="$t(`供应商`)"
            :code.sync="formData.supplierCode"
            :name.sync="formData.supplierName"
            valueName="supplier_code"
            labelName="supplier_name"
          />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :placeholder="$t('单据类型')"
            :code.sync="formData.orderType"
            :list="deposit_payment_type"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>

      <CommonTable
        class="mt10"
        ref="CommonTableRef"
        :statusList="deposit_payment_status"
        :showSelect="true"
        :selectedRowKeys.sync="selectedRowKeys"
        :selectedRows.sync="selectedRows"
        :scroll="{ x: 1500, y: scrollHeight }"
        :loading="tableLoading"
        :columns.sync="tableColumns"
        :data-source="tableData"
        :total="total"
        rowKey="id"
        @select="onSelectChange"
        @search="queryOrderList"
      >
        <template v-slot:header>
          <div class="flex-ct">
            <OperateBtn
              api="checkOrder"
              :text="$t('审核')"
              :params="{
                docType: docType,
                idList: selectedRowKeys,
              }"
              :disabled="selectedRows.length === 0"
              @success="searchHandle"
            />
            <PushBtn
              class="ml10"
              :docType="docType"
              :selectedRows="selectedRows"
              :disabled="selectedRows.length === 0"
            />
            <VoucherBtn
              class="ml10"
              :docType="docType"
              :selectedRows="selectedRows"
              :disabled="selectedRows.length === 0"
              @close="searchHandle"
            />
          </div>
        </template>
        <!-- 预付单号 -->
        <template v-slot:orderSn="{ text, record }">
          <a @click="viewDetail(record)">{{ text }}</a>
        </template>
        <template v-slot:sourceOrderCode="{ record }">
          <a>{{ record.sourceOrderCode }}</a>
        </template>
        <!-- 业务单号 -->
        <template v-slot:otherSns="{ record }">
          <div>
            {{ $t('采购单号') }}:
            <span class="text-link">{{ record.purchaseOrderSn }}</span>
          </div>
          <div>
            {{ $t('预到货通知单号') }}:
            <span class="text-link">{{ record.asnOrderSn }}</span>
          </div>
          <div>
            {{ $t('入库单号') }}:
            <span class="text-link">{{ record.receivedOrderSn }}</span>
          </div>
        </template>
        <!-- 金额（未含税） -->
        <template v-slot:amountWithoutTax="{ text, record }">
          <PriceInput :value="text" :showTxt="true" :currency="record.settlement_currency" />
        </template>
        <!-- 税额 -->
        <template v-slot:taxAmount="{ text, record }">
          <PriceInput :value="text" :showTxt="true" :currency="record.settlement_currency" />
        </template>
        <!-- 价税合计 -->
        <template v-slot:payableAmount="{ text, record }">
          <PriceInput :value="text" :showTxt="true" :currency="record.settlement_currency" />
        </template>
        <!-- 开票金额 -->
        <template v-slot:invoicedAmount="{ text, record }">
          <span v-if="text">
            <PriceInput :value="text" :showTxt="true" :currency="record.settlement_currency" />
          </span>
        </template>
      </CommonTable>
    </section>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import CommonSelect from '@component/commonSelect'
import CommonTable from '@component/commonTable'
import PriceInput from '@component/priceInput'
import PushBtn from '@component/pushBtn'
import VoucherBtn from '@component/voucherBtn'
import { convertKeysToCamelCase, spaceToComma } from '@/common'
import { subtract } from '@/common/number'
export default {
  name: 'depositPaymentList',
  activated() {
    this.searchHandle()
  },
  components: {
    CommonSelect,
    CommonTable,
    PriceInput,
    PushBtn,
    VoucherBtn,
  },
  data() {
    return {
      docType: 'DepositPayment',
      formData: {
        orderSn: undefined,
        referenceNo: undefined,
        bizDateStart: undefined,
        bizDateEnd: undefined,
        status: '',
        supplierCode: undefined,
      },
      tableLoading: false,
      total: 0,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      visible: false,
      waitInvoiceList: [],
      nextVisible: false,

      tableColumns: [
        {
          dataIndex: 'orderSn',
          title: this.$t('预付单号'),
          key: 'orderSn',
          width: 200,
          scopedSlots: { customRender: 'orderSn' },
        },
        {
          title: this.$t('预付单状态'),
          width: 150,
          dataIndex: 'status',
          customRender: (text) => this.depositPaymentStatusMapping[text],
        },
        {
          title: this.$t('预付单类型'),
          width: 150,
          dataIndex: 'orderType',
          customRender: (text) => this.depositPaymentTypeMapping[text],
        },
        {
          title: this.$t('供应商'),
          width: 150,
          dataIndex: 'supplierName',
        },
        {
          title: this.$t('支付时间'),
          width: 200,
          dataIndex: 'payTime',
        },
        {
          title: this.$t('金额') + '(' + this.$t('未含税') + ')',
          width: 150,
          dataIndex: 'amountWithoutTax',
          scopedSlots: { customRender: 'amountWithoutTax' },
        },
        {
          title: this.$t('税额'),
          width: 150,
          dataIndex: 'taxAmount',
          scopedSlots: { customRender: 'taxAmount' },
        },
        {
          title: this.$t('价税合计'),
          width: 150,
          dataIndex: 'payableAmount',
          scopedSlots: { customRender: 'payableAmount' },
        },
        {
          title: this.$t('业务单号'),
          dataIndex: 'otherSns',
          width: 320,
          scopedSlots: { customRender: 'otherSns' },
        },
        {
          title: this.$t('源单类型'),
          dataIndex: 'sourceOrderType',
          width: 150,
          customRender: (text) => this.sourceOrderTypeMapping[text],
        },
        {
          title: this.$t('源单编码'),
          dataIndex: 'sourceOrderCode',
          width: 180,
          scopedSlots: { customRender: 'sourceOrderCode' },
        },
        {
          title: this.$t('备注'),
          width: 120,
          dataIndex: 'remark',
        },
        {
          title: this.$t('创建时间'),
          dataIndex: 'createdAt',
          width: 200,
        },
      ],
    }
  },
  computed: {
    ...mapState(['deposit_payment_status', 'deposit_payment_type']),
    ...mapGetters([
      'depositPaymentTypeMapping',
      'depositPaymentStatusMapping',
      'sourceOrderTypeMapping',
    ]),
    scrollHeight() {
      return window.innerHeight - 470
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.searchHandle(1)
    },
    subtract,
    searchHandle(pageNo) {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.waitInvoiceList = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    createHandle() {
      this.$router.push({
        name: 'depositPaymentDetail',
      })
    },
    viewDetail(record) {
      this.addPane({
        view: 'depositPaymentDetail/' + record.orderSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.orderSn,
        },
        route: `/payableManage/depositPaymentDetail?id=${record.id}&sn=${record.orderSn}`,
      })
    },
    chooseBizDate(date) {
      this.formData.bizDateStart = date.start_time
      this.formData.bizDateEnd = date.end_time
    },
    chooseArrivalStart(date) {
      this.formData.arrivalTimeStart = date.start_time
      this.formData.arrivalTimeEnd = date.end_time
    },
    async queryOrderList(params) {
      let data = {
        ...this.formData,
        ...params,
      }
      let orderSn = spaceToComma(this.formData.orderSn)
      if (orderSn.includes(',')) {
        data.orderSnList = orderSn.split(',')
      } else if (orderSn) {
        data.orderSn = orderSn
      }
      this.tableLoading = true
      let res = await http({
        type: 'post',
        url: api.getDepositPaymentList,
        data,
      })
      let purchaseOrderList = [...(res.result.list || '')]
      this.tableData = convertKeysToCamelCase(purchaseOrderList)
      this.total = res.result.total
      this.tableLoading = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      let waitInvoiceList = selectedRows.filter((item) => item.invoiceStatus === 'WAIT_INVOICE')
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.waitInvoiceList = waitInvoiceList
    },
  },
}
</script>
